$primary: #1c1c1c;
$secondary: lighten($primary, 50%);
//$hairline: lighten($primary, 65%);
$hairline: lighten($primary, 80%);

$big-space: 12vw;
$small-space: 2vw;

// $m-big-space: 10vw;
// $m-small-space: 10vw;

$iphone-se-frame-width: 38vw;
$iphone-x-frame-width: 35vw;
$iphone-4s-frame-width: 38vw;
$full-frame-width:76vw;
$icon-width: 68vw;

$iphone-se-frame-width-mob: 55vw;
$iphone-x-frame-width-mob: 60vw;
$iphone-4s-frame-width-mob: 60vw;
$full-frame-width-mob: 84vw;

$iphone-se-frame-margin: 10vw;
// $iphone-se-frame-insets: 33.15% 11.75% 32% 11.75%;
$iphone-se-frame-insets: 32.5% 10.6% 31.9% 10.6%;

$iphone-4s-frame-margin: 11vw;
// $iphone-4s-frame-insets: 36.5% 14.2% 36% 14.2%;
$iphone-4s-frame-insets: 36% 12.7% 36% 12.7%;

$iphone-x-frame-margin: 11vw;
$iphone-x-frame-insets: 12.9% 10%;

$web-frame-margin: 4vw;
$web-frame-insets: 4.1% 12.3% 7% 12.3%;
// $web-frame-insets: 3.6% 0 0 0;

$breakpoints: (
	xs: 576px,
	sm: 768px,
	md: 992px,
	lg: 1200px
);

@mixin breakpoint($breakpoint) {
	// If the breakpoint exists in the map.
	@if map-has-key($breakpoints, $breakpoint) {
		// Get the breakpoint value.
		$breakpoint-value: map-get($breakpoints, $breakpoint);

		// Write the media query.
		@media (max-width: ($breakpoint-value - 1)) {
			@content;
		}

		// If the breakpoint doesn't exist in the map.
	} @else {
		// Log a warning.
		@warn 'Invalid breakpoint: #{$breakpoint}.';
	}
}
