@import url(//hello.myfonts.net/count/3b3a1c);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after {
  content: '';
  content: none; }

q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

@font-face {
  src: url(/static/media/font.f3db85bf.eot);
  font-family: 'Fracktif';
  src: url(/static/media/font.9005951c.woff2) format("woff2"), url(/static/media/font.58ef046e.woff) format("woff"), url(/static/media/font.f3db85bf.eot?#iefix) format("embedded-opentype"), url(/static/media/font.f9fefcfe.ttf) format("truetype");
  font-weight: 400; }

@font-face {
  src: url(/static/media/font.9ad7c0d0.eot);
  font-family: 'Fracktif';
  src: url(/static/media/font.3c68a197.woff2) format("woff2"), url(/static/media/font.fdc605ae.woff) format("woff"), url(/static/media/font.9ad7c0d0.eot?#iefix) format("embedded-opentype"), url(/static/media/font.9f8dee47.ttf) format("truetype");
  font-weight: 500; }

body {
  font-size: 2.8vw;
  line-height: 1.3;
  font-family: "Fracktif", sans-serif; }
  @media (max-width: 767px) {
    body {
      font-size: 6.8vw; } }

.small, .collaboration, .role, .tile-text, .replay-btn {
  font-size: 1.5vw;
  line-height: 1.3; }
  @media (max-width: 767px) {
    .small, .collaboration, .role, .tile-text, .replay-btn {
      font-size: 4.5vw; } }

h3 {
  font-weight: 500;
  background-size: 100vw; }

.huge {
  font-size: 7vw;
  font-weight: 500;
  background-size: 100vw; }
  @media (max-width: 767px) {
    .huge {
      font-size: 11vw; } }

p {
  color: #9c9c9c;
  margin: 10px 0; }
  p:last-child {
    margin-bottom: 0; }

a {
  color: #1c1c1c;
  text-decoration: none; }

/* Building Blocks */
.section {
  margin-left: auto;
  margin-right: auto;
  padding: 0 12vw; }
  @media (max-width: 767px) {
    .section {
      padding: 0 8vw; } }

.project {
  width: 100%;
  position: relative; }

.split {
  display: flex;
  align-items: flex-start;
  justify-content: space-between; }
  @media (max-width: 767px) {
    .split {
      flex-flow: row wrap; } }

img,
video {
  max-width: 100%;
  display: block;
  -webkit-transition: 0.2s opacity;
  transition: 0.2s opacity; }

.u-link {
  display: inline-block; }

.u-link::after {
  content: "";
  display: block;
  height: 2px;
  width: 100%;
  margin-top: -8px;
  background-color: #4f4f4f;
  -webkit-transition: -webkit-transform 0.2s cubic-bezier(0.29, 0.63, 0.44, 1);
  transition: -webkit-transform 0.2s cubic-bezier(0.29, 0.63, 0.44, 1);
  transition: transform 0.2s cubic-bezier(0.29, 0.63, 0.44, 1);
  transition: transform 0.2s cubic-bezier(0.29, 0.63, 0.44, 1), -webkit-transform 0.2s cubic-bezier(0.29, 0.63, 0.44, 1);
  -webkit-transform-origin: right center;
          transform-origin: right center;
  -webkit-transform: scale(0, 1); }

.u-link:hover::after,
.u-link.active::after {
  -webkit-transform: scale(1, 1);
          transform: scale(1, 1);
  -webkit-transform-origin: left center;
          transform-origin: left center; }

.f-link,
.f-link svg {
  -webkit-transition: all 0.2s;
  transition: all 0.2s; }

.f-link:hover {
  color: #1c1c1c; }

/* Layout */
body {
  background-color: #fff;
  -webkit-transition: background-color 0.5s;
  transition: background-color 0.5s;
  background-size: 390px 390px;
  -webkit-animation: fadeInImg 1200ms;
          animation: fadeInImg 1200ms; }

.caret {
  cursor: pointer;
  display: block;
  -webkit-transform: translateY(50%);
          transform: translateY(50%);
  display: flex;
  align-items: center;
  animation: 1.2s infinite point alternate-reverse; }
  .caret svg {
    width: 3.5vw; }
  @media (max-width: 767px) {
    .caret svg {
      width: 8vw;
      height: 6vh; } }

.caret span {
  display: inline-block;
  padding-left: 2vw;
  -webkit-animation: fadeInImg cubic-bezier(0.23, 1, 0.32, 1);
          animation: fadeInImg cubic-bezier(0.23, 1, 0.32, 1);
  -webkit-animation-duration: 4s;
          animation-duration: 4s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  opacity: 0;
  -webkit-animation-delay: 5s;
          animation-delay: 5s; }
  @media (max-width: 767px) {
    .caret span {
      padding-left: 4vw; } }

.spacer {
  width: 100%;
  display: flex; }

.spacer span {
  color: #9c9c9c;
  display: none;
  flex-grow: 1; }
  @media (max-width: 767px) {
    .spacer span {
      display: block; } }

@-webkit-keyframes point {
  0% {
    -webkit-transform: translateY(60%);
            transform: translateY(60%); }
  100% {
    -webkit-transform: translateY(40%);
            transform: translateY(40%); } }

@keyframes point {
  0% {
    -webkit-transform: translateY(60%);
            transform: translateY(60%); }
  100% {
    -webkit-transform: translateY(40%);
            transform: translateY(40%); } }

.intro {
  top: 0;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start; }
  @media (max-width: 767px) {
    .intro {
      height: 100vh;
      height: calc(1vh * 100);
      height: calc(var(--vh, 1vh) * 100); } }
  .intro .intro-photo {
    width: 100%; }
  .intro .hero {
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    overflow: hidden;
    -webkit-animation: 5s fadeInImg;
            animation: 5s fadeInImg; }
  .intro .overlay {
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    overflow: hidden;
    background-image: -webkit-radial-gradient(rgba(255, 255, 255, 0) 30%, #fff);
    background-image: radial-gradient(rgba(255, 255, 255, 0) 30%, #fff); }
  .intro .split {
    align-items: flex-end; }
    @media (max-width: 767px) {
      .intro .split {
        flex-direction: column-reverse; } }
  .intro .details {
    z-index: 1;
    box-sizing: border-box; }

.hero svg {
  width: 100%;
  height: 100%; }

.hero svg path,
.hero svg rect,
.hero svg circle,
.hero svg line {
  fill: transparent;
  stroke: #cfcfcf;
  stroke-width: 1px; }

#portholeMask {
  fill: black; }

.intro #circle1 {
  stroke: #cfcfcf;
  fill: transparent;
  stroke-width: 102px; }

.intro #circle2 {
  stroke: white;
  fill: transparent;
  stroke-width: 100px; }

@media (max-width: 767px) {
  .huge {
    margin-bottom: 12vh; } }

.project {
  padding-top: 12vw; }
  @media (max-width: 767px) {
    .project {
      margin-bottom: 12vw; } }

.split .details {
  position: sticky;
  position: -webkit-sticky;
  width: 35%; }
  @media (max-width: 767px) {
    .split .details {
      position: static;
      width: 100%; } }

.wide .details {
  padding-bottom: 4vw; }
  @media (max-width: 767px) {
    .wide .details {
      position: static;
      width: 100%; } }

.details {
  top: 12vw; }

.tile-list ul {
  list-style: none;
  padding: 0;
  margin: 0; }

.split .tile-list {
  width: 65%;
  padding-left: 2vw; }
  @media (max-width: 767px) {
    .split .tile-list {
      width: 100%;
      padding-left: 0; } }

.wide .tile {
  width: 100vw;
  background-repeat: repeat;
  background-size: 100vw;
  background-color: #f2f2f2;
  margin-bottom: 2vw;
  display: flex;
  justify-content: center;
  padding: 8vw 0;
  margin-left: -12vw; }
  @media (max-width: 767px) {
    .wide .tile {
      padding: 14vw 0;
      margin-left: -8vw; } }
  .wide .tile.tile-cover {
    background-color: blue; }
  .wide .tile:last-child {
    margin-bottom: 0; }
  @media (max-width: 767px) {
    .wide .tile {
      margin-bottom: 8vw; }
      .wide .tile:first-child {
        margin-top: 2vw; } }

.preview-container {
  margin: auto;
  position: relative;
  pointer-events: inherit;
  /* Frame settings */
  /* Apply to all cropped previews */ }
  .preview-container.vid-container {
    cursor: wait; }
    .preview-container.vid-container.loaded {
      cursor: default; }
  .preview-container .preview-frame {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: top center;
    position: relative;
    pointer-events: none;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    -webkit-mask-size: 100% 100%;
    mask-size: 100% 100%; }
  .preview-container .preview-screen {
    position: absolute;
    z-index: 1;
    pointer-events: inherit;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0; }
  .preview-container img.poster,
  .preview-container video.vid {
    position: absolute;
    pointer-events: inherit;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%; }
  .preview-container img.preview {
    max-height: 100%;
    margin: auto; }

@-webkit-keyframes fadeInImg {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadeInImg {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }
  .preview-container .img-loading {
    opacity: 0;
    width: 100%;
    height: auto;
    cursor: wait; }
  .preview-container .img-loaded {
    -webkit-animation: fadeInImg cubic-bezier(0.23, 1, 0.32, 1) 1;
            animation: fadeInImg cubic-bezier(0.23, 1, 0.32, 1) 1;
    position: relative;
    opacity: 0;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
    -webkit-animation-duration: 2s;
            animation-duration: 2s;
    -webkit-animation-delay: 0.1s;
            animation-delay: 0.1s; }
  .preview-container .vid {
    -webkit-transition: opacity 0.7s cubic-bezier(0.23, 1, 0.32, 1);
    transition: opacity 0.7s cubic-bezier(0.23, 1, 0.32, 1); }
  .preview-container .vid-idle {
    opacity: 0; }
  .preview-container .vid-playing {
    opacity: 1; }
  .preview-container .red {
    border: 10px solid red; }
  .preview-container.web, .preview-container.square {
    box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.08); }
    .preview-container.web .preview-frame, .preview-container.square .preview-frame {
      width: 76vw;
      height: calc(76vw * 0.625); }
      @media (max-width: 767px) {
        .preview-container.web .preview-frame, .preview-container.square .preview-frame {
          width: 84vw;
          height: calc(84vw * 0.625); } }
  .preview-container.square {
    box-shadow: none; }
  .preview-container.iphone-se .preview-frame {
    width: 38vw;
    height: calc(38vw * 2.0728);
    background-image: url(/static/media/iphone-se.c856ee61.jpg);
    -webkit-mask-image: url(/static/media/iphone-se-mask.ef2d68d0.svg);
    mask-image: url(/static/media/iphone-se-mask.ef2d68d0.svg); }
    @media (max-width: 767px) {
      .preview-container.iphone-se .preview-frame {
        width: 55vw;
        height: calc(55vw * 2.0728); } }
  .preview-container.iphone-se .preview-screen {
    top: 14.86%;
    left: 8.32%;
    right: 8.32%;
    bottom: 13.86%; }
  .preview-container.iphone-4s .preview-frame {
    width: 38vw;
    height: calc(38vw * 1.932);
    background-image: url(/static/media/iphone-4s.c6b0890a.jpg);
    -webkit-mask-image: url(/static/media/iphone-4s-mask.dd34d956.svg);
    mask-image: url(/static/media/iphone-4s-mask.dd34d956.svg); }
    @media (max-width: 767px) {
      .preview-container.iphone-4s .preview-frame {
        width: 60vw;
        height: calc(60vw * 1.932); } }
  .preview-container.iphone-4s .preview-screen {
    top: 17.99%;
    left: 8.32%;
    right: 8.32%;
    bottom: 17.32%; }
  .preview-container.iphone-x .preview-frame {
    width: 35vw;
    height: calc(35vw * 2);
    background-image: url(/static/media/iphone-x.a1c2049f.jpg);
    -webkit-mask-image: url(/static/media/iphone-x-mask.4f3276c0.svg);
    mask-image: url(/static/media/iphone-x-mask.4f3276c0.svg); }
    @media (max-width: 767px) {
      .preview-container.iphone-x .preview-frame {
        width: 60vw;
        height: calc(60vw * 2); } }
  .preview-container.iphone-x .preview-screen {
    top: 2.94%;
    left: 6.56%;
    right: 6.56%;
    bottom: 2.94%; }

.preview-content {
  position: relative;
  z-index: 1; }

.preview-image-stage {
  position: relative;
  border-radius: 2px;
  overflow: hidden;
  padding-bottom: 66%;
  background-color: red; }

.preview-image-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.collaboration {
  color: #9c9c9c;
  margin-top: 2vw; }
  @media (max-width: 767px) {
    .collaboration {
      display: inline-block;
      margin-top: 4vw; } }

.role {
  color: #9c9c9c;
  display: inline-block; }

.tile-text {
  padding-left: 4vw;
  padding-right: 4vw;
  padding-bottom: 3vw;
  text-align: center;
  color: #1c1c1c; }
  @media (max-width: 767px) {
    .tile-text {
      padding-left: 8vw;
      padding-right: 8vw;
      padding-bottom: 8vw; } }

.nav-right {
  position: fixed;
  right: 0;
  width: 0;
  background-color: red;
  height: 100%;
  box-sizing: border-box;
  z-index: 100; }
  @media (max-width: 767px) {
    .nav-right {
      display: none; } }

.nav-left {
  position: fixed;
  left: 0;
  width: 8vw;
  height: 100%;
  z-index: 100;
  padding-left: 2vw; }
  @media (max-width: 767px) {
    .nav-left {
      display: none; } }

.project-list {
  -webkit-transition: opacity 0.3s ease-out;
  transition: opacity 0.3s ease-out; }
  .project-list div:first-child .project {
    padding-top: 0; }

.project-list.dim {
  opacity: 0.3; }

.nav-target {
  background-color: rgba(255, 0, 0, 0.2); }

.project-nav {
  position: absolute;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center; }
  .project-nav * {
    -webkit-transition: height 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
    transition: height 0.3s cubic-bezier(0.075, 0.82, 0.165, 1); }
  .project-nav:hover .tick:before {
    width: 50% !important;
    background-color: #1c1c1c;
    height: 2px !important; }
  .project-nav .tick {
    cursor: pointer;
    display: block;
    height: 100%;
    width: 100%;
    position: relative;
    display: block;
    display: flex; }
    .project-nav .tick .label {
      white-space: nowrap;
      left: 150%;
      display: block;
      position: absolute;
      top: 50%;
      -webkit-transform: translateY(-47%);
              transform: translateY(-47%);
      opacity: 0;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      color: #1c1c1c;
      cursor: pointer;
      pointer-events: none; }
    .project-nav .tick:hover .label {
      opacity: 1; }
    .project-nav .tick.active:before {
      width: 100%;
      height: 4px; }
    .project-nav .tick:before {
      content: "";
      position: absolute;
      top: 50%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%);
      -webkit-transition: width 0.1s ease-out;
      transition: width 0.1s ease-out;
      background-color: #9c9c9c;
      height: 2px;
      width: 50%;
      left: 50%;
      display: block; }
    .project-nav .tick:hover .label {
      color: #1c1c1c; }

.site-title {
  display: block;
  position: absolute;
  top: 2vw;
  left: -1.6vw;
  font-size: 2.5vw;
  -webkit-transform-origin: 0 0;
          transform-origin: 0 0;
  -webkit-transform: rotateZ(90deg);
          transform: rotateZ(90deg);
  white-space: nowrap; }
  .site-title a {
    color: #9c9c9c; }

.socials {
  position: fixed;
  bottom: 2vw;
  right: 2vw; }
  .socials li {
    padding-top: 1vw; }
  .socials a svg {
    fill: #9c9c9c;
    height: 24px;
    width: 24px; }
  .socials a:hover svg {
    fill: #1c1c1c; }
  @media (max-width: 767px) {
    .socials {
      position: static;
      align-content: flex-end; }
      .socials ul {
        display: flex; }
        .socials ul li {
          padding-left: 4vw; } }

.footer {
  margin: 12vw 0; }

.sketch-loader {
  display: inline-block;
  position: relative; }

.sketch-loader span {
  display: block;
  -webkit-animation: text-fill 1.2s 0.3s cubic-bezier(0.29, 0.63, 0.44, 1) forwards;
          animation: text-fill 1.2s 0.3s cubic-bezier(0.29, 0.63, 0.44, 1) forwards;
  background: -webkit-gradient(linear, left top, right top, color-stop(20%, #1c1c1c), color-stop(40%, #1c1c1c), color-stop(60%, #fff), color-stop(80%, #fff));
  background: -webkit-linear-gradient(left, #1c1c1c 20%, #1c1c1c 40%, #fff 60%, #fff 80%);
  background: linear-gradient(to right, #1c1c1c 20%, #1c1c1c 40%, #fff 60%, #fff 80%);
  background-size: 300% auto;
  background-position: 100%;
  color: #000;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; }

.hero-desc {
  opacity: 0;
  -webkit-animation: fadeInImg 1.2s 0.3s cubic-bezier(0.29, 0.63, 0.44, 1) forwards;
          animation: fadeInImg 1.2s 0.3s cubic-bezier(0.29, 0.63, 0.44, 1) forwards;
  -webkit-animation-delay: 1.5s; }

.intro .sketch-loader:before {
  top: 19px; }

.intro .sketch-loader span:before {
  left: 7px; }

.intro .sketch-loader.line-2 {
  -webkit-animation-delay: 0.8s; }
  .intro .sketch-loader.line-2 span {
    -webkit-animation-delay: 0.8s; }
  .intro .sketch-loader.line-2:after, .intro .sketch-loader.line-2:before,
  .intro .sketch-loader.line-2 span:before,
  .intro .sketch-loader.line-2 span:after {
    -webkit-animation-delay: 0.8s; }

/* @keyframes duration | timing-function | delay | 
iteration-count | direction | fill-mode | play-state | name */
/* bottom */
.sketch-loader:after {
  content: "";
  width: calc(100% + 15%* 2);
  left: -15%;
  bottom: 23%;
  height: 1px;
  display: block;
  background: #000;
  position: absolute;
  -webkit-animation: wipe 1.2s 0.5s cubic-bezier(0.29, 0.63, 0.44, 1) forwards;
          animation: wipe 1.2s 0.5s cubic-bezier(0.29, 0.63, 0.44, 1) forwards;
  -webkit-transform: scale(0, 1);
          transform: scale(0, 1);
  z-index: -1; }

/* top */
.sketch-loader:before {
  content: "";
  width: calc(100% + 15%* 2);
  height: 1px;
  left: -15%;
  top: 10%;
  display: block;
  background: #000;
  position: absolute;
  -webkit-animation: wipe 1.2s 0.3s cubic-bezier(0.29, 0.63, 0.44, 1) forwards;
          animation: wipe 1.2s 0.3s cubic-bezier(0.29, 0.63, 0.44, 1) forwards;
  -webkit-transform: scale(0, 1);
          transform: scale(0, 1);
  z-index: -1; }

/* left */
.sketch-loader span:before {
  content: "";
  height: calc(100% + 15%* 2 - 10%* 2);
  width: 1px;
  display: block;
  background: #000;
  left: 0;
  top: -15%;
  position: absolute;
  -webkit-animation: wipe-down 1.2s 0.3s cubic-bezier(0.29, 0.63, 0.44, 1) forwards;
          animation: wipe-down 1.2s 0.3s cubic-bezier(0.29, 0.63, 0.44, 1) forwards;
  -webkit-transform-origin: top;
          transform-origin: top;
  -webkit-transform: scale(1, 0);
          transform: scale(1, 0);
  z-index: -1; }

/* right */
.sketch-loader span:after {
  content: "";
  height: calc(100% + 15%* 2 - 10%* 2);
  width: 1px;
  display: block;
  background: #000;
  right: 0;
  top: -15%;
  position: absolute;
  -webkit-animation: wipe-down 1.2s 0.5s cubic-bezier(0.29, 0.63, 0.44, 1) forwards;
          animation: wipe-down 1.2s 0.5s cubic-bezier(0.29, 0.63, 0.44, 1) forwards;
  -webkit-transform-origin: top;
          transform-origin: top;
  -webkit-transform: scale(1, 0);
          transform: scale(1, 0); }

@-webkit-keyframes wipe {
  0% {
    -webkit-transform: scale(0, 1);
            transform: scale(0, 1);
    -webkit-transform-origin: left center;
            transform-origin: left center; }
  50% {
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
    -webkit-transform-origin: left center;
            transform-origin: left center; }
  50.01% {
    -webkit-transform-origin: right center;
            transform-origin: right center; }
  100% {
    -webkit-transform: scale(0, 1);
            transform: scale(0, 1);
    -webkit-transform-origin: right center;
            transform-origin: right center; } }

@keyframes wipe {
  0% {
    -webkit-transform: scale(0, 1);
            transform: scale(0, 1);
    -webkit-transform-origin: left center;
            transform-origin: left center; }
  50% {
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
    -webkit-transform-origin: left center;
            transform-origin: left center; }
  50.01% {
    -webkit-transform-origin: right center;
            transform-origin: right center; }
  100% {
    -webkit-transform: scale(0, 1);
            transform: scale(0, 1);
    -webkit-transform-origin: right center;
            transform-origin: right center; } }

@-webkit-keyframes wipe-down {
  0% {
    -webkit-transform: scale(1, 0);
            transform: scale(1, 0);
    -webkit-transform-origin: top;
            transform-origin: top; }
  50% {
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
    -webkit-transform-origin: top;
            transform-origin: top; }
  50.01% {
    -webkit-transform-origin: bottom;
            transform-origin: bottom; }
  100% {
    -webkit-transform: scale(1, 0);
            transform: scale(1, 0);
    -webkit-transform-origin: bottom;
            transform-origin: bottom; } }

@keyframes wipe-down {
  0% {
    -webkit-transform: scale(1, 0);
            transform: scale(1, 0);
    -webkit-transform-origin: top;
            transform-origin: top; }
  50% {
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
    -webkit-transform-origin: top;
            transform-origin: top; }
  50.01% {
    -webkit-transform-origin: bottom;
            transform-origin: bottom; }
  100% {
    -webkit-transform: scale(1, 0);
            transform: scale(1, 0);
    -webkit-transform-origin: bottom;
            transform-origin: bottom; } }

@-webkit-keyframes text-fill {
  to {
    background-position: 0% center; } }

@keyframes text-fill {
  to {
    background-position: 0% center; } }

.replay {
  color: #9c9c9c;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2vw;
  overflow: hidden;
  padding-bottom: 4vw;
  padding-top: 2vw; }
  @media (max-width: 767px) {
    .replay {
      padding-top: 6vw;
      padding-bottom: 6vw; } }
  .replay :hover {
    color: #1c1c1c; }
    .replay :hover svg {
      fill: #1c1c1c; }
  .replay svg {
    fill: #1c1c1c;
    -webkit-backface-visibility: hidden;
    padding-left: 4px;
    margin-bottom: -3px;
    width: 16px;
    height: 16px; }

.replay-btn {
  background: none;
  color: #1c1c1c;
  border: none;
  cursor: pointer;
  -webkit-transition: 0.1s;
  transition: 0.1s; }
  .hidden .replay-btn {
    opacity: 0; }

.poster {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0; }
  .poster.loading {
    -webkit-animation: loading infinite 1.5s ease-out;
            animation: loading infinite 1.5s ease-out; }

@-webkit-keyframes loading {
  0% {
    opacity: 1; }
  50% {
    opacity: 0.2; }
  100% {
    opacity: 1; } }

@keyframes loading {
  0% {
    opacity: 1; }
  50% {
    opacity: 0.2; }
  100% {
    opacity: 1; } }

