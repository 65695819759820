// @import url("https://fonts.googleapis.com/css?family=Roboto");
// @import url("https://fonts.googleapis.com/css?family=Roboto+Mono");
// @import url("https://fonts.googleapis.com/css?family=Roboto+Condensed");

// @font-face {
// 	font-family: "Founders Grotesk";
// 	src: url("./assets/fonts/FoundersGroteskRegular.otf");
// 	font-weight: 400;
// }
// @font-face {
// 	font-family: "Founders Grotesk";
// 	src: url("./assets/fonts/FoundersGroteskMedium.otf");
// 	font-weight: 500;
// }
// @font-face {
// 	font-family: "Founders Grotesk";
// 	src: url("./assets/fonts/FoundersGroteskBold.otf");
// 	font-weight: 600;
// }

// @font-face {
// 	font-family: "GreycliffCF";
// 	src: url("./assets/fonts/GreycliffCF-Medium.otf");
// 	font-weight: 500;
// }
// @font-face {
// 	font-family: "GreycliffCF";
// 	src: url("./assets/fonts/GreycliffCF-Heavy.otf");
// 	font-weight: 600;
// }

@import url("//hello.myfonts.net/count/3b3a1c");
  
@font-face {
  src: url('./assets/fonts/Fracktif-Regular/font.eot');
  font-family: 'Fracktif';
  src: url('./assets/fonts/Fracktif-Regular/font.woff2') format('woff2'), url('./assets/fonts/Fracktif-Regular/font.woff') format('woff'), url('./assets/fonts/Fracktif-Regular/font.eot?#iefix') format('embedded-opentype'), url('./assets/fonts/Fracktif-Regular/font.ttf') format('truetype');
  	font-weight: 400;

}
@font-face {
  src: url('./assets/fonts/Fracktif-SemiBold/font.eot');
  font-family:'Fracktif' ;
  src: url('./assets/fonts/Fracktif-SemiBold/font.woff2') format('woff2'), url('./assets/fonts/Fracktif-SemiBold/font.woff') format('woff'), url('./assets/fonts/Fracktif-SemiBold/font.eot?#iefix') format('embedded-opentype'), url('./assets/fonts/Fracktif-SemiBold/font.ttf') format('truetype');
  	font-weight: 500;

}



// @font-face {
// 	font-family: "Fracktif";
// 	src: url("./assets/fonts/Fracktif-Regular.otf");
// 	font-weight: 400;
// }
// @font-face {
// 	font-family: "Fracktif";
// 	src: url("./assets/fonts/Fracktif-SemiBold.otf");
// 	font-weight: 500;
// }

// @font-face {
// 	font-family: "QuincyCF";
// 	src: url("./assets/fonts/QuincyCF-Regular.otf");
// 	font-weight: 500;
// }
// @font-face {
// 	font-family: "QuincyCF";
// 	src: url("./assets/fonts/QuincyCF-Black.ttf");
// 	font-weight: 600;
// }

body {
	font-size: 2.8vw;
	line-height: 1.3;
	font-family: "Fracktif", sans-serif;
	@include breakpoint(sm) {
		font-size: 6.8vw;
	}
}

// .tile-text {
// 	font-family: 'Founders Grotesk Condense;' !important;
// }

.small {
	font-size: 1.5vw;
	line-height: 1.3;
	// font-family: "NeutrifPro";
	@include breakpoint(sm) {
		font-size: 4.5vw;
	}
}

h3 {
	font-weight: 500;

	// -webkit-text-fill-color: transparent;
	// -webkit-background-clip: text;

	// background-image: url("./assets/textures/water.png");
	background-size: 100vw;
	// font-family: "QuincyCF", serif;
}

// .tile-text {
// 	-webkit-text-fill-color: transparent;
// 	-webkit-background-clip: text;
// 	background-image: url("./assets/textures/light-tile.png");
// 	background-size: 50%;
// }

// .big {
// 	font-size: 3.5vw;
// 	line-height: 1.2;

// 	@include breakpoint(sm) {
// 		font-size: 5.8vw;
// 	}
// }

.huge {
	font-size: 7vw;
	font-weight: 500;

	// -webkit-text-fill-color: transparent;
	// -webkit-background-clip: text;

	// background-image: url("./assets/textures/turf.png");
	background-size: 100vw;
	// font-family: "QuincyCF", serif;

	@include breakpoint(sm) {
		font-size: 11vw;
	}
}

p {
	color: $secondary;
	margin: 10px 0;
	&:last-child {
		margin-bottom: 0;
	}
}

a {
	color: $primary;
	text-decoration: none;
}
