@import "styles/_reset.scss";
@import "styles/_colors.scss";
@import "styles/_typography.scss";

/* Building Blocks */

.section {
  margin-left: auto;
  margin-right: auto;
  // max-width: 1024px;
  padding: 0 $big-space;
  @include breakpoint(sm) {
    padding: 0 8vw;
  }
}

.project {
  width: 100%;
  position: relative;
}

.split {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  @include breakpoint(sm) {
    flex-flow: row wrap;
  }
}

img,
video {
  max-width: 100%;
  display: block;
  transition: 0.2s opacity;
}

video {
  // z-index: 10;
  // position: absolute;
}

.u-link {
  display: inline-block;
}

.u-link::after {
  content: "";
  display: block;
  height: 2px;
  width: 100%;
  margin-top: -8px;
  background-color: lighten($primary, 20%);
  transition: transform 0.2s cubic-bezier(0.29, 0.63, 0.44, 1);
  transform-origin: right center;
  -webkit-transform: scale(0, 1);
}

.u-link:hover::after,
.u-link.active::after {
  transform: scale(1, 1);
  transform-origin: left center;
}

.f-link,
.f-link svg {
  transition: all 0.2s;
}

.f-link:hover {
  color: $primary;
}

/* Layout */

body {
  background-color: #fff;
  transition: background-color 0.5s;
  // background-image: url("./assets/textures/light-tile.png");
  background-size: 390px 390px;
  animation: fadeInImg 1200ms;
}
.caret {
  cursor: pointer;
  display: block;
  // bottom: 4vw;
  // background-color: red;
  // position: absolute;
  transform: translateY(50%);
  display: flex;
  align-items: center;
  svg {
    width: 3.5vw;
  }
  @include breakpoint(sm) {
    //   height: calc(var(--vh, 1vh) * 100);

    //   left: 8vw;
    //   bottom: 5vh;
    svg {
      width: 8vw;
      height: 6vh;
    }
  }
  animation: 1.2s infinite point alternate-reverse;
}

.caret span {
  display: inline-block;
  padding-left: $small-space;
  animation: fadeInImg cubic-bezier(0.23, 1, 0.32, 1);
  animation-duration: 4s;
  animation-fill-mode: forwards;
  opacity: 0;
  animation-delay: 5s;
  @include breakpoint(sm) {
    padding-left: $small-space * 2;
  }
}

.spacer {
  width: 100%;
  display: flex;
}
.spacer span {
  color: $secondary;
  display: none;
  flex-grow: 1;
  @include breakpoint(sm) {
    display: block;
  }
}

@keyframes point {
  0% {
    transform: translateY(60%);
  }
  100% {
    transform: translateY(40%);
  }
}

.intro {
  top: 0;
  height: 100vh;
  // margin-bottom: $big-space;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;

  @include breakpoint(sm) {
    // margin-bottom: $big-space;
    height: 100vh;
    // adjusts to viewport height with toolbars
    height: calc(var(--vh, 1vh) * 100);

    // display: flex;
    // flex-direction: row;
    // justify-content: flex-end;
    // align-items: flex-end;
  }

  .intro-photo {
    width: 100%;
  }

  .hero {
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    overflow: hidden;
    animation: 5s fadeInImg;
    // background-color: #f2f2f2;
      // background-image: radial-gradient(#f2f2f2, #f2f2f2, #fff);

    // display: none;
  }

  .overlay {
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    overflow: hidden;
          background-image: radial-gradient(rgba(255,255,255,0) 30%, #fff);

    // background-image: linear-gradient(to bottom, rgba(255,255,255,0) 50%, white);
  }

  // .tile {
  //   background-color: #f2f2f2;
  //   padding: 0;
  //   height: calc(100vh - 12vw);
  //   @include breakpoint(sm) {
  //     flex: 1;
  //   }
  //   display: flex;
  //   flex-direction: row;
  //   justify-content: flex-end;
  //   align-items: flex-end;
  // }

  .split {
    align-items: flex-end;
    @include breakpoint(sm) {
      // Put intro pattern above intro text on mobile
      flex-direction: column-reverse;
    }
  }

  .details {
    // height: 50vh;
    // background-color: red;
    // margin-top: 25vh;
    z-index: 1;
    // display: flex;
    // flex-direction: column;
    // justify-content: center;
    // align-items: flex-start;
    // padding-bottom: 12vh;
    box-sizing: border-box;
    // border-bottom:2px solid $primary;
    // @include breakpoint(sm) {
    //   height: auto;
    //   margin-bottom: 12vh;
    // }
  }
}


.hero svg {
  width: 100%;
  height: 100%;
}

.hero svg {
  path,
  rect,
  circle,
  line {
    fill: transparent;
    stroke: lighten($secondary, 20%);
    stroke-width: 1px;
  }
}

#portholeMask {
  fill: black;
}

.intro #circle1 {
  stroke: lighten($secondary, 20%);
  fill: transparent;
  stroke-width: 102px;
}

.intro #circle2 {
  stroke: white;
  fill: transparent;
  stroke-width: 100px;
}

.huge {
    @include breakpoint(sm) {
    margin-bottom: 12vh;
  }
}

.project {
  padding-top: $big-space;
  // &:first-child {
  //   padding-top: 0;
  // }

  @include breakpoint(sm) {
    margin-bottom: $big-space;
  }
}

.split {
  .details {
    position: sticky;
    position: -webkit-sticky;
    width: 35%;
    @include breakpoint(sm) {
      position: static;
      width: 100%;
    }
  }
}

.wide {
  .details {
    padding-bottom: $small-space * 2;
    @include breakpoint(sm) {
      position: static;
      width: 100%;
    }
  }
}

.details {
  // @extend .big;
  top: $big-space;
}

.tile-list {
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
}

.split .tile-list {
  width: 65%;
  // max-width: 600px;
  padding-left: $small-space;
  @include breakpoint(sm) {
    // margin-top: $small-space * 2;
    width: 100%;
    padding-left: 0;
  }
}

.wide .tile {
  width: 100vw;
  // background-image: url("./assets/textures/space-light.png");
  background-repeat: repeat;
  background-size: 100vw;
  // background-color: #e6dcd4;
  // background-color: #f8f8f8;
  background-color: #f2f2f2;
  // background-color: rgba(255,255,255,0.3);
  margin-bottom: $small-space;
  display: flex;
  justify-content: center;

  padding: $small-space * 4 0;

  margin-left: -$big-space;

  // box-shadow: 10px 0 30px rgba(0,0,0,0.1);

  @include breakpoint(sm) {
    padding: $small-space * 7 0;
    margin-left: -8vw;
  }

  &.tile-cover {
    background-color: blue;
  }

  &:last-child {
    margin-bottom: 0;
  }

  @include breakpoint(sm) {
    margin-bottom: $small-space * 4;
    &:first-child {
      margin-top: $small-space;
    }
  }
}

.preview-container {
  // display: flex;
  // align-items: center;
  // justify-content: center;
  // overflow: hidden;
  // padding-top: $small-space * 2;
  // padding-bottom: $small-space * 2;
  margin: auto;
  // width: 100%;
  position: relative;
  pointer-events: inherit;

  // animation: loading infinite 1.5s ease-out;
  // &:before {
  //   content: "Loading";
  //   display: block;
  //   position: absolute;
  //   z-index: 3;
  //   color: white;
  //   left: 50%;
  //   top: 50%;
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  //   border-radius: 10vw;
  //   height: 20vw;
  //   width: 20vw;
  //   transform: translate(-50%, -50%);
  //   background-color: rgba(0, 0, 0, 0.8);
  // }
  // &.loaded {
  //   &:before {
  //     content: none;
  //     display: none;
  //   }
  //   animation: none;
  // }

  // @include breakpoint(sm) {
  //   &.loaded:before {
  //     content: "Play";
  //     display: block;
  //     position: absolute;
  //     z-index: 3;
  //     color: white;
  //     left: 50%;
  //     top: 50%;
  //     border-radius: 10vw;
  //     height: 20vw;
  //     width: 20vw;
  //     transform: translate(-50%, -50%);
  //     background-color: rgba(0, 0, 0, 0.8);
  //   }
  // }

  &.vid-container {
    cursor: wait;
    &.loaded {
      cursor: default;
    }
  }

  .preview-frame {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    background-size: 100% 100%;
    // background-color: darken(#f2f2f2, 10%);
    background-repeat: no-repeat;
    background-position: top center;
    position: relative;
    pointer-events: none;

    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    -webkit-mask-size: 100% 100%;
    mask-size: 100% 100%;
  }

  .preview-screen {
    position: absolute;
    z-index: 1;
    pointer-events: inherit;

    // Default screen is edge-to-edge (no-insets)
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    // animation: text-fill infinite 1s 0.3s;
    // background: linear-gradient(to right, #f2f2f2 30%, #f2f2f2 40%, white 60%, #f2f2f2 70%);
    // background-size: 400% auto;
    // background-position: 100%;
  }

  img.poster,
  video.vid {
    position: absolute;
    pointer-events: inherit;

    // Default screen is edge-to-edge (no-insets)
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
  }

  img.preview {
    max-height: 100%;
    margin: auto;
  }

  @keyframes fadeInImg {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  .img-loading {
    opacity: 0;
    width: 100%;
    height: auto;
    cursor: wait;
  //     &:before {
  //   content: "Loading";
  //   display: block;
  //   position: absolute;
  //   z-index: 3;
  //   color: white;
  //   left: 50%;
  //   top: 50%;
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  //   border-radius: 10vw;
  //   height: 20vw;
  //   width: 20vw;
  //   transform: translate(-50%, -50%);
  //   background-color: rgba(0, 0, 0, 0.8);
  // }
  }
  .img-loaded {
    animation: fadeInImg cubic-bezier(0.23, 1, 0.32, 1) 1;
    position: relative;
    opacity: 0;
    animation-fill-mode: forwards;
    animation-duration: 2s;
    animation-delay: 0.1s;
  }

  .vid {
    transition: opacity 0.7s cubic-bezier(0.23, 1, 0.32, 1); //delay prevents flicker
  }

  // .vid-loading {
  //   animation: loading infinite 1.5s ease-out;
  // }

  .vid-idle {
    opacity: 0;
  }
  .vid-playing {
    opacity: 1;
  }

  .red {
    border: 10px solid red;
  }

  /* Frame settings */

  &.web {
    box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.08);

    .preview-frame {
      width: $full-frame-width;
      height: calc(#{$full-frame-width} * 0.625);

      @include breakpoint(sm) {
        width: $full-frame-width-mob;
        height: calc(#{$full-frame-width-mob} * 0.625);
      }
    }
  }

  &.square {
    @extend .web;
    box-shadow: none;

    // @include breakpoint(sm) {
    //   width: $full-frame-width-mob;
    //   height: $full-frame-width-mob;
    // }
  }

  &.iphone-se {
    .preview-frame {
      width: $iphone-se-frame-width;
      height: calc(#{$iphone-se-frame-width} * 2.0728);
      @include breakpoint(sm) {
        width: $iphone-se-frame-width-mob;
        height: calc(#{$iphone-se-frame-width-mob} * 2.0728);
      }
      background-image: url("./assets/frames/iphone-se.jpg");
      -webkit-mask-image: url("./assets/frames/iphone-se-mask.svg");
      mask-image: url("./assets/frames/iphone-se-mask.svg");
    }
    .preview-screen {
      top: 14.86%;
      left: 8.32%;
      right: 8.32%;
      bottom: 13.86%;
    }
  }

  &.iphone-4s {
    .preview-frame {
      width: $iphone-4s-frame-width;
      height: calc(#{$iphone-4s-frame-width} * 1.932);
      @include breakpoint(sm) {
        width: $iphone-4s-frame-width-mob;
        height: calc(#{$iphone-4s-frame-width-mob} * 1.932);
      }
      background-image: url("./assets/frames/iphone-4s.jpg");
      -webkit-mask-image: url("./assets/frames/iphone-4s-mask.svg");
      mask-image: url("./assets/frames/iphone-4s-mask.svg");
    }
    .preview-screen {
      top: 17.99%;
      left: 8.32%;
      right: 8.32%;
      bottom: 17.32%;
    }
  }

  &.iphone-x {
    .preview-frame {
      width: $iphone-x-frame-width;
      height: calc(#{$iphone-x-frame-width} * 2);
      @include breakpoint(sm) {
        width: $iphone-x-frame-width-mob;
        height: calc(#{$iphone-x-frame-width-mob} * 2);
      }
      background-image: url("./assets/frames/iphone-x.jpg");
      -webkit-mask-image: url("./assets/frames/iphone-x-mask.svg");
      mask-image: url("./assets/frames/iphone-x-mask.svg");
    }
    .preview-screen {
      top: 2.94%;
      left: 6.56%;
      right: 6.56%;
      bottom: 2.94%;
    }
  }

  /* Apply to all cropped previews */
  // .has-caption & {
  //   &.iphone-se,
  //   &.iphone-4s,
  //   &.iphone-x {
  //     margin: 0 $small-space * 2;
  //     @include breakpoint(sm) {
  //       margin: 0 $small-space * 4;
  //     }
  //     padding-bottom: 0;

  //     .preview-frame {
  //       padding-bottom: 0;
  //       margin-bottom: -2px;
  //     }
  //   }
  //   &.iphone-x {
  //     padding-left: calc(#{$iphone-x-frame-margin} - 4vw);
  //     padding-right: calc(#{$iphone-x-frame-margin} - 4vw);
  //     border-bottom: 2px solid $hairline;

  //     .preview-frame {
  //       margin-bottom: -13%;
  //     }
  //   }
  //   &.iphone-4s {
  //     padding-left: calc(#{$iphone-4s-frame-margin} - 4vw);
  //     padding-right: calc(#{$iphone-4s-frame-margin} - 4vw);
  //     border-bottom: 2px solid $hairline;
  //   }
  //   &.iphone-se {
  //     padding-left: calc(#{$iphone-se-frame-margin} - 4vw);
  //     padding-right: calc(#{$iphone-se-frame-margin} - 4vw);
  //     border-bottom: 2px solid $hairline;
  //   }
  //   &.web {
  //     margin: 0 $small-space * 2;
  //     @include breakpoint(sm) {
  //       margin: 0 $small-space * 4;
  //     }
  //     padding-bottom: 0;

  //     padding-left: calc(#{$web-frame-margin} - 4vw);
  //     padding-right: calc(#{$web-frame-margin} - 4vw);
  //   }
  // }
}

.preview-content {
  position: relative;
  z-index: 1;
}

.preview-image-stage {
  position: relative;
  border-radius: 2px;
  overflow: hidden;
  padding-bottom: 66%;
  background-color: red;
}

.preview-image-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.collaboration {
  @extend .small;
  color: $secondary;
  margin-top: $small-space;
  @include breakpoint(sm) {
    display: inline-block;

    margin-top: $small-space * 2;
  }
}

.role {
  @extend .small;
  color: $secondary;
  display: inline-block;
  // text-transform: uppercase;
}

.tile-text {
  @extend .small;

  // text-shadow: 0px 1px 0px rgba(255,255,225,0.75);
  padding-left: $small-space * 2;
  padding-right: $small-space * 2;
  padding-bottom: $small-space * 1.5;
  // margin-top: $small-space * 2;
  // margin-top: -1vw;

  @include breakpoint(sm) {
    padding-left: $small-space * 4;
    padding-right: $small-space * 4;
    padding-bottom: $small-space * 4;
    // margin-top: $small-space * 4;
  }

  // color: #f8f8f8;
  text-align: center;
  color: $primary;

  .tile-title {
    // color: $primary;
    // padding-right: 4px;
  }
}

.nav-right {
  position: fixed;
  right: 0;
  width: 0;
  background-color: red;
  height: 100%;
  box-sizing: border-box;
  z-index: 100;

  @include breakpoint(sm) {
    display: none;
  }
}

.nav-left {
  position: fixed;
  // display: flex;
  // align-items: center;
  left: 0;
  width: 8vw;
  height: 100%;
  z-index: 100;
  padding-left: 2vw;
  // box-sizing: border-box;

  @include breakpoint(sm) {
    display: none;
  }
}

.project-list {
  // text-align: center;
  transition: opacity 0.3s ease-out;
  div:first-child .project {
    padding-top: 0;
  }
}
.project-list.dim {
  opacity: 0.3;
  // filter: blur(2px);
  // transform: scale(0.99);
}

.nav-target {
  background-color: rgba(255, 0, 0, 0.2);
}

.project-nav {
  position: absolute;
  // width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  * {
    transition: height 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
  }

  &:hover {
    * {
      // transition: none !important;
    }
  }

  &:hover {
    .tick:before {
      // transition: none;
      width: 50% !important;
      background-color: $primary;
      height: 2px !important;
    }
  }

  .tick {
    // overflow: hidden;
    cursor: pointer;
    display: block;
    height: 100%;
    width: 100%;
    position: relative;
    display: block;
    display: flex;
    // transform-origin: 0% 0%;

    .label {
      white-space: nowrap;
      left: 150%;
      // height: 100%;
      display: block;
      // background-color: rgba(255,0,0,0.1);
      position: absolute;
      top: 50%;
      transform: translateY(-47%);
      opacity: 0;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      // padding-left: 0.5vw;
      color: $primary;
      cursor: pointer;
      pointer-events: none;

      // overflow: hidden;
    }

    &:hover .label {
      opacity: 1;
    }

    &.active {
      &:before {
        // background-color: $primary;
        width: 100%;
        height: 4px;
      }
      .label {
        // color: $primary;
        // left: 100%;
      }
    }

    &:before {
      // tick
      content: "";
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      transition: width 0.1s ease-out;
      background-color: $secondary;
      height: 2px;
      width: 50%;
      left: 50%;
      display: block;
      // transition: width 0.1s;
    }

    &:hover {
      &:before {
        // background-color: $primary;
      }
      .label {
        color: $primary;
      }
    }
  }
}

.site-title {
  display: block;
  position: absolute;
  top: $small-space;
  left: -1.6vw;
  a {
    color: $secondary;
  }
  font-size: 2.5vw;

  transform-origin: 0 0;
  transform: rotateZ(90deg);
  white-space: nowrap;
}

.socials {
  position: fixed;
  bottom: $small-space;
  right: $small-space;

  li {
    padding-top: $small-space/2;
  }
  a svg {
    fill: $secondary;
    height: 24px;
    width: 24px;
  }

  a:hover svg {
    fill: $primary;
  }

  @include breakpoint(sm) {
    position: static;
    align-content: flex-end;
    ul {
      display: flex;
      li {
        padding-left: $small-space * 2;
      }
    }
  }
}

.footer {
  margin: $big-space 0;
}

// $line-color: linear-gradient(124deg, #fff, #000, #fff);
$line-width: 1px;
$line-color: #000;
$sketch-duration: 1.2s;
$sketch-overflow: 15%;

$sketch-bottom-offset: 23%;
$sketch-top-offset: 10%;
$easing-function: cubic-bezier(0.29, 0.63, 0.44, 1);

.sketch-loader {
  display: inline-block;
  position: relative;
}
.sketch-loader span {
  display: block;
  animation: text-fill $sketch-duration 0.3s $easing-function forwards;
  background: linear-gradient(
    to right,
    $primary 20%,
    $primary 40%,
    #fff 60%,
    #fff 80%
  );
  background-size: 300% auto;
  background-position: 100%;
  color: #000;
  background-clip: text;
  // transform: translateX(20px);
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.hero-desc {
  opacity: 0;
  animation: fadeInImg $sketch-duration 0.3s $easing-function forwards;
    -webkit-animation-delay: 1.5s;

}

.intro .sketch-loader {
  &:before {
    top: 19px;
  }
  span:before {
    left: 7px;
  }
}

.intro .sketch-loader.line-2{
  -webkit-animation-delay: 0.8s;

  span {
  -webkit-animation-delay: 0.8s;
  }

  &:after,
  &:before,
  span:before,
  span:after {
    -webkit-animation-delay: 0.8s;
  }
}

/* @keyframes duration | timing-function | delay | 
iteration-count | direction | fill-mode | play-state | name */

/* bottom */
.sketch-loader:after {
  content: "";
  width: calc(100% + #{$sketch-overflow}* 2);
  left: -$sketch-overflow;
  bottom: $sketch-bottom-offset;
  height: $line-width;
  display: block;
  background: $line-color;
  position: absolute;
  animation: wipe $sketch-duration 0.5s $easing-function forwards;
  transform: scale(0, 1);
  z-index: -1;
}

/* top */
.sketch-loader:before {
  content: "";
  width: calc(100% + #{$sketch-overflow}* 2);
  height: $line-width;
  left: -$sketch-overflow;
  top: $sketch-top-offset;
  display: block;
  background: $line-color;
  position: absolute;
  animation: wipe $sketch-duration 0.3s $easing-function forwards;
  transform: scale(0, 1);
  z-index: -1;
}

/* left */
.sketch-loader span:before {
  content: "";
  height: calc(100% + #{$sketch-overflow}* 2 - #{$sketch-top-offset}* 2);
  width: $line-width;
  display: block;
  background: $line-color;
  left: 0;
  top: -$sketch-overflow;
  position: absolute;
  animation: wipe-down $sketch-duration 0.3s $easing-function forwards;
  transform-origin: top;
  transform: scale(1, 0);
  z-index: -1;
}

/* right */
.sketch-loader span:after {
  content: "";
  height: calc(100% + #{$sketch-overflow}* 2 - #{$sketch-top-offset}* 2);
  width: $line-width;
  display: block;
  background: $line-color;
  right: 0;
  top: -$sketch-overflow;
  position: absolute;
  animation: wipe-down $sketch-duration 0.5s $easing-function forwards;
  transform-origin: top;
  transform: scale(1, 0);
}

@keyframes wipe {
  0% {
    transform: scale(0, 1);
    transform-origin: left center;
  }

  50% {
    transform: scale(1, 1);
    transform-origin: left center;
  }
  50.01% {
    transform-origin: right center;
  }

  100% {
    transform: scale(0, 1);
    transform-origin: right center;
  }
}

@keyframes wipe-down {
  0% {
    transform: scale(1, 0);
    transform-origin: top;
  }

  50% {
    transform: scale(1, 1);
    transform-origin: top;
  }
  50.01% {
    transform-origin: bottom;
  }

  100% {
    transform: scale(1, 0);
    transform-origin: bottom;
  }
}

@keyframes text-fill {
  to {
    // transform: translateX(0);
    background-position: 0% center;
  }
}

.replay {
  color: $secondary;
  display: flex;
  // height: $small-space * 3;
  justify-content: center;
  align-items: center;
  height: $small-space;
  overflow: hidden;
  padding-bottom: $small-space * 2;
  padding-top: $small-space;

  @include breakpoint(sm) {
    padding-top: $small-space * 3;
    padding-bottom: $small-space * 3;
  }
  // position: relative;
  // -webkit-backface-visibility: hidden;

  :hover {
    color: $primary;

    svg {
      fill: $primary;
      // transform: rotate(-360deg);
    }
  }

  svg {
    fill: $primary;
    -webkit-backface-visibility: hidden;
    padding-left: 4px;
    margin-bottom: -3px;
    width: 16px;
    height: 16px;
    // transition: 0.2s;
  }
}

.replay-btn {
  @extend .small;
  background: none;
  color: $primary;
  border: none;
  cursor: pointer;
  transition: 0.1s;
  // background-color: $secondary;
  // padding: 8px 14px 0px 16px;
  // border-radius: 30px;
  // position: absolute;
  // top:$small-space;
  &:hover {
  }
  .hidden & {
    opacity: 0;
  }
}

.poster {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  &.loading {
    animation: loading infinite 1.5s ease-out;
  }
}
@keyframes loading {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
}

// #windowFill {

//   animation: wipe infinite $sketch-duration 0.2s cubic-bezier(0.29, 0.63, 0.44, 1)
//     forwards;
//   transform-origin: top;
//   transform: scale(1, 0);
// }

// @keyframes wipe {
//   0% {
//     transform: scale(0, 1);
//     transform-origin: left center;
//   }

//   50% {
//     transform: scale(1, 1);
//     transform-origin: left center;
//   }
//   50.01% {
//     transform-origin: right center;
//   }

//   100% {
//     transform: scale(0, 1);
//     transform-origin: right center;
//   }
// }

// #arcG { animation: rot 5s linear infinite; }

// #arc {
//   fill: none;
//   stroke: #000;
//   stroke-width: 16;
//   animation: ani 4s linear infinite reverse,
//     rot 16s steps(4, start) infinite reverse;
// }
// @keyframes ani {
//   0% {
//     stroke-dasharray: 755 1006;
//     stroke-dashoffset: 0;
//     animation-timing-function: cubic-bezier(0.895, 0.03, 0.685, 0.22);
//   }
//   49.999% {
//     stroke-dasharray: 0 1006;
//     stroke-dashoffset: 0;
//   }
//   50.001% {
//     stroke-dasharray: 0 1006;
//     stroke-dashoffset: -1006;
//     animation-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
//   }
//   100% {
//     stroke-dasharray: 755 1006;
//     stroke-dashoffset: -251;
//   }
// }
// @keyframes rot { to { transform: rotate(1turn); } }
